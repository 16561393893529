<template>
  <div id="header">
      <div id= "logo" v-on:click="$parent.showpage('default')"><inline-svg :src="require('../assets/logotypemotion.svg')" ></inline-svg></div>
    </div>
</template>
<script>

import InlineSvg from 'vue-inline-svg';
 
// Your component

export default {
  name: 'Logo',
  components: {
        InlineSvg,
  }
}
</script>

<style >
#header{
  max-height: 200px;
  
}
#logo{
  cursor: pointer;
}

svg{
  width: 200px;
  height: 45px;
  fill: var(--fontcolor);
}

</style>