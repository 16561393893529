<template>
  <div id="menue" v-bind:class="{open: open}">
     <div class='main'>
      <ul class="main-nav">
          <li><a v-on:click="openPage('about')">About</a></li>
          <li><a>References</a></li>
          <li><a>Pictures</a></li> 
      </ul>
      <div class="close-btn" v-on:click="closeMenue"><span>close</span></div>
        <ul class="footer-nav">
          <li><a v-on:click="openPage('about')">Impressum</a></li>
          <li><a>Contact</a></li>
          <li><a>Work</a></li> 
      </ul>
    </div>
    <div class='opener' v-on:click="openMenue">
        <span></span>
        <span></span>
        <span></span>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Menue',
  data(){
	return {
		open: false,
    }
  },
  methods:{
	openMenue(){
		this.open = true;
	},
    closeMenue(){
		this.open = false;
	},
    openPage(page){
        this.$parent.showpage(page);
        this.open = false;
    }
  }
}
</script>

<style >
 #menue .main{
     background: var(--fontcolor);
     color: black;
     z-index: 1;
     position: absolute;
     top: 0;
     left: -370px;
     bottom: 0;
     width: 270px;
     padding: 140px 50px 100px;
     transition: left 1s;
         display: flex;
    flex-direction: column;
    justify-content: space-between;
     
 }
 #menue .main ul {
     list-style-type: none;
     margin:  0;
     padding: 0;
 }
 #menue .main ul.main-nav li {
     margin-bottom: 7px;
 }
 #menue .main ul.main-nav li a {
     font-size: 40px;
     font-family: "soehne-fett";
 }
 #menue .main ul.footer-nav li {
     margin-bottom: 5px;
}
  #menue .main ul.footer-nav li a {
     font-size: 18px;
     font-family: "soehne-mono";
     text-transform: uppercase;
 }
   #menue .main ul.footer-nav li a:before {
       content: '<';
   }
    #menue .main ul.footer-nav li a:after {
       content: '>';
   }

 #menue .close-btn {
     position: absolute;
    top: 44vh;
    right: 20px;
    padding: 20px;
    cursor: pointer;

 }
 #menue .close-btn span {
    width: 70px;    
    height: 4px;
    background: black;
    text-indent: -10000px;
    display: block;
     cursor: pointer;
 }

#menue.open .main{
     left: 0;     
 }

 #menue .opener{
     position: absolute;
     left: 50px;
     top: 43vh;
     bottom: 0;
     margin: auto;
     cursor: ponter;
     display: block;
     z-index: 1;
     cursor: pointer;
     transition: left 0.1s;
     
 }
 #menue .opener span {
     height: 70px;
     width: 4px;
     background: var(--fontcolor);;
     display: block;
     float: left;
     margin: 0 2px;
      cursor: ponter;
 }
  #menue.open .opener{
     left: -20px;
 }

 a{
     cursor:pointer;
 }
 



</style>