<template>
  <div id="app">
    
    <Menue></Menue>
    <div id='holder'>
    <Header></Header>
    <Result v-if="page === 'default'" ref="Result" ></Result>
    <Selectors v-if="page === 'default'" ref="Selectors"></Selectors>
    <About v-if="page === 'about'"></About>
    </div>
  </div>
</template>

<script>
import Menue from './components/Menue.vue'
import Header from './components/Header.vue'
import Selectors from './components/Selectors.vue'
import Result from './components/Result.vue'
import About from './components/About.vue'

 

export default {
  name: 'App',
  data(){
    return {
      page: 'default',
      bg: 'default',
      font: 'GT Flexa VAR'
    }
  },
  methods:{
    showpage(pagename){
      this.page = pagename;
    },
    setBackgrond(bg){
      this.bg = bg;
      if(bg != 'default'){
         document.querySelector('body').className = bg;
      }else{
        document.querySelector('body').className = '';
      } 
    },
    setBackgroundColor(r,g,b){
      let root = document.documentElement;
      root.style.setProperty('--bgcolor', "rgb("+r+", "+g+", "+b+")");
    },
    setFontColor(r,g,b){
      window.fcr = r;
      window.fcg = g;
      window.fcb = b;
      let root = document.documentElement;
      root.style.setProperty('--fontcolor', "rgb("+r+", "+g+", "+b+")");
    },
    setFont(fontname){
      this.font = fontname;
      this.$refs.Result.setFont();
    }
  },
  components: {
   Menue, Header, Selectors,Result,About
  }
}
</script>

<style>
@import './assets/main.css';
</style>
