<template>
  <div id="buttons">
    <div class="dropup wide">
      <button class="dropbtn" onclick="this.parentElement.classList.toggle('open')"><span
          class="label">Background</span><span class="icon"></span></button>
      <div class="dropup-content">
        <div class="holder">
          <a href="#" v-on:click="$parent.setBackgrond('default'); background ='default' "><img src="" alt=""/>plain <br>Background <i v-if="background == 'default' " class="voll" /> <i v-else /></a>
          <a href="#" v-on:click="$parent.setBackgrond('supermysticgradient');br = 35;bg=166,bb=213; background ='supermysticgradient' ">Super Mystic<br>Gradient <i v-if="background == 'supermysticgradient' " class="voll" /> <i v-else /></a>
          <a href="#" v-on:click="$parent.setBackgrond('plaingrainbackground');br = 40;bg=40,bb=40; background ='plaingrainbackground'">Plain Grain<br>Background<i v-if="background == 'plaingrainbackground' " class="voll" /> <i v-else /></a>
          <a href="#" v-on:click="$parent.setBackgrond('opticalbulbillustion');br = 35;bg=166,bb=213; background ='opticalbulbillustion' ">Optical Bulb<br>Illusion<i v-if="background == 'opticalbulbillustion' " class="voll" /> <i v-else /></a>
        </div>
      </div>
    </div>
    <div class="dropup wide">
      <button class="dropbtn" onclick="this.parentElement.classList.toggle('open')"><span
          class="label">Background Color</span><span class="icon"></span></button>
      <div class="dropup-content">
        <div class="holder sliders">
          <span>Choose Color</span>
          <div><label for="color_r">R</label><input type="range" min="0" max="255" v-model="br" class="slider"
                                                    id="color_r"/></div>
          <div><label for="color_g">G</label><input type="range" min="0" max="255" v-model="bg" value="0" class="slider"
                                                    id="color_g"/></div>
          <div><label for="color_b">B</label><input type="range" min="0" max="255" v-model="bb" value="0" class="slider"
                                                    id="color_b"/></div>
        </div>
      </div>
    </div>
    <div class="dropup color">
      <button class="dropbtn" onclick="this.parentElement.classList.toggle('open')"><span
          class="label">Font Color</span><span class="icon"></span></button>
      <div class="dropup-content">
        <div class="holder">
          <a href="#" v-on:click="$parent.setFontColor(0,0,255);" style="background-color: rgb(0,0,255)"></a>
          <a href="#" v-on:click="$parent.setFontColor(255,255,255)" style="background-color: rgb(255,255,255)" ></a>
          <a href="#" v-on:click="$parent.setFontColor(255,0,80)" style="background-color: rgb(255,0,80)" ></a>
          <a href="#" v-on:click="$parent.setFontColor(255,255,0)" style="background-color: rgb(255,255,0)" ></a>
        </div>
      </div>
    </div>
    <div class="dropup wide">
      <button class="dropbtn" onclick="this.parentElement.classList.toggle('open')"><span class="label">Typefaces</span><span
          class="icon"></span></button>
      <div class="dropup-content font">
        <div class="holder">
          <a class="f-fgx" v-on:click="$parent.setFont('phase-fgx'); font = 'phase-fgx'" href="#">Phase FGX  <i v-if="font == 'phase-fgx' " class="voll" /> <i v-else /></a>
          <a class="f-hgx" v-on:click="$parent.setFont('phase-hgx'); font ='phase-hgx' " href="#">Phase HGX <i v-if="font == 'phase-hgx' " class="voll" /> <i v-else /> </a>
          <a class="f-dgx" v-on:click="$parent.setFont('phase-dgx'); font ='phase-dgx' " href="#">Phase DGX <i v-if="font == 'phase-dgx' " class="voll" /> <i v-else /></a>
          <a class="f-gflex" v-on:click="$parent.setFont('GT Flexa VAR'); font = 'GT Flexa VAR'" href="#">GT Flexa <i v-if="font == 'GT Flexa VAR' " class="voll" /> <i v-else /></a>
          <a class="f-cheee" v-on:click="$parent.setFont('cheee');  font = 'cheee' " href="#">Cheee <i v-if="font == 'cheee' " class="voll" /> <i v-else /></a>
        </div>
      </div>
    </div>
    <div class="dropup wide">
      <button class="dropbtn" onclick="this.parentElement.classList.toggle('open')"><span class="label">Audiofile</span><span
          class="icon"></span></button>
      <div class="dropup-content audio">
      <div class="holder">
          <a href="#" v-on:click="startsound('startEt'); ">ET<br><span>»Et zu Haus <br>telefonieren«</span> <i v-if="audio == 'startEt' " class="voll" /> <i v-else /></a>
          <a href="#" v-on:click="startsound('startHousten'); ">Housten<br><span> »Houston, wir<br> haben ein Problem«</span><i v-if="audio == 'startHousten' " class="voll" /> <i v-else /></a>
          <a href="#" v-on:click="startsound('startOld'); ">Lethal Weapon<br><span>»I´m to old for<br>this shit«</span><i v-if="audio == 'startOld' " class="voll" /> <i v-else /></a>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: 'Selectors',
	data(){
		return{
			br: 0,
			bg: 0,
			bb: 0,
      font: 'GT Flexa VAR',
      background: 'default',
      fontcolor: 'white',
      audio: ''
		}	
	},
  methods:{
    startsound(version){
      if(this.audio == ''){
        this.audio = version;
        this.$parent.$refs.Result.startrec();
        let event = new Event(version);
        window.dispatchEvent(event);
      }

    },
    doneRec(){
      this.audio = '';
    }
  },
	watch: {
        br: function() {
           this.$parent.setBackgroundColor(this.br,this.bg,this.bb)
        },
		bg: function() {
           this.$parent.setBackgroundColor(this.br,this.bg,this.bb)
        },
		bb: function() {
           this.$parent.setBackgroundColor(this.br,this.bg,this.bb)
        }
    }
}
</script>

<style>

.f-fgx{
	font-family: 'phase-fgx';
	font-variation-settings: "phon" 67, "phtw" 9, "phth" 45;
	font-size: 2em;
}



.f-hgx{
	font-family: 'phase-hgx';
	font-variation-settings: "phon" 67, "phtw" 9, "phth" 45;
	font-size: 2em;
}

.f-dgx{
	font-family: 'phase-dgx';
	font-variation-settings: "phon" 67, "phtw" 9, "phth" 45;
	font-size: 2em;
}

.f-gflex{
	font-family: 'GT Flexa VAR';
	font-variation-settings: 'wght' 317, 'wdth' 100, 'ital' 100;
	font-size: 2em;
}

.f-cheee{
	font-family: 'cheee';
	font-variation-settings: "yest" 0, "grvt" 430, "temp" 700;
	font-size: 2em;
}


#buttons {
	align-self: flex-end;
	display:grid;
	grid-column-gap: 30px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.dropbtn {
	background: transparent;
	width: 100%;
	border: 2px solid var(--fontcolor);;
	color: var(--fontcolor);;
	text-align: left;
	padding: 10px 10px 10px 20px;
	font-family: 'soehne-mono';
	text-transform: uppercase;
	font-size: 18px;
	line-height: 1;
	height: 45px;
	position: relative;
}

.dropbtn .icon {
	border: 2px solid var(--fontcolor);;
	width: 24px;
	height: 24px;
	display: block;
  position: absolute;
	text-align: center;
  top: 6px;
  bottom: 6px;
  right: 6px;
}
.dropbtn .icon:after {
	content: '+';
	font-size: 25px;
	line-height: 20px;
}
.dropbtn .label:before {
	content: '<';
	line-height: 1;
}
.dropbtn .label:after {
	content: '>';
	line-height: 1;
}

.dropup-content {
  font-family: 'soehne-mono';
  display: none;
  position: absolute;
  bottom: 44px;
  width: 100%;
  z-index: 1;
  border: 2px solid var(--fontcolor);;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 35px;
}

.dropup-content a{
  position: relative;
}

.dropup-content a i{
  display: block;
  border: 2px var(--fontcolor) solid;
  background: transparent;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 10px;
  top: 8px

}

.audio.dropup-content a i{
  right: 10px;
  top: 21px

}

.dropup-content i.voll{
  background: var(--fontcolor);
}

.dropup-content-color .holder {
  font-family: 'soehne-mono';
  display: none;
  position: absolute;
  bottom: 44px;
  width: 100%;
  z-index: 1;
  border: 2px solid var(--fontcolor);;
  box-sizing: border-box;
  padding: 10px;
  padding-left: 35px;
}
.dropup-content span{
	font-family: 'soehne-fett';
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.color .dropup-content{
  padding: 10px;
}

.color .dropup-content .holder {
	padding-left: 0;
	background: transparent;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}
.color .dropup-content .holder a{
  border: 2px var(--fontcolor) solid;
}

.wide .dropup-content .holder {
	padding: 10px;
	background: transparent;
	display: grid;
	
	grid-template-columns: 2fr;
	grid-gap: 10px;
}

.dropup-content a {
  color: var(--fontcolor);
  text-decoration: none;
  display: block;
  border: 0px solid var(--fontcolor);;
	height: 57px;
  font-size: 18px;

}

.dropup-content.font a{
  font-size: 30px;
  height: 45px;
}

.dropup-content.audio a {
  font-family: 'soehne-fett';
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 8px 0px;
}

.dropup-content.audio a span {
  font-family: 'soehne-mono';
  padding-bottom: 5px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}



.dropup {
  position: relative;

}

.dropup:hover .dropbtn{
  background: var(--fontcolor);;
  color: var(--bgcolor);
}

.dropup:hover .dropbtn .icon{
   border-color: var(--background);
}

.dropup.open .dropup-content{
   display: block;
}

.holder.sliders > div{
	display: grid;
	grid-template-columns: 1fr 4fr 1fr;
	align-items: center;
	justify-items: left;
	height: 25px;
  font-family: 'soehne-mono';
  font-size: 18px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 0px;  
  background: var(--fontcolor);
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 0%; 
  background: var(--fontcolor);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  border-radius: 0%; 
  background: var(--fontcolor);
  cursor: pointer;
}

.holder{
  display: block;
  font-family:'soehne-mono';

}

</style>