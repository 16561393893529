<template>
<div id="results">
	<div id = "text">
		<p class="variable" v-bind:style="'font-variation-settings: ' + fontVars +'font-family: \'' + font +'\''"> {{text}} </p>
		<button v-if="!listening" v-on:click="startrec">Starte Spracherkennung</button>
		
	</div>
	<div id="debug">
	<h4>Input Parameters</h4>
	<div class="results">
		<div class="item"><span>volume</span> {{volume.toPrecision(2)}}<div class="result-slider"><div v-bind:style="'width:'+percvolume+'%'"></div></div></div>
		<!--<div class="item"><span>font:</span> {{fontvolume}}</div>-->
		<div class="item"><span>pitch</span> {{centroid}} Hz <div class="result-slider"><div v-bind:style="'width:'+percpitch+'%'"></div></div></div>
		<!--<div class="item"><span>fontpitch:</span> {{fontpitch}}</div>-->
		<div class="item"><span>sentiment</span> {{percsentiment}}<div class="result-slider"><div v-bind:style="'width:'+percsentiment+'%'"></div></div></div>
	<!--	<div class="item"><span>font CASL</span> {{fontcasl}} </div>-->
	</div>
	</div>
</div>	
</template>

<script>

const ml = require('../sentiment-analyser-master/src/index.js')({lang: 'de'});



function map (n, start1, stop1, start2, stop2, withinBounds) {
  const newval = (n - start1) / (stop1 - start1) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return constrain(newval, start2, stop2);
  } else {
    return constrain(newval, stop2, start2);
  }
}

function constrain (n, low, high) {
  return Math.max(Math.min(n, high), low);
}



export default {
  name: 'Result',
  data(){
	return {
		text: "Drücke Start",
		sentiment: 0,
		volume: 0,
		percvolume: 0,
		percpitch: 0,
		percsentiment: 0,
		centroid: 0,
		fontpitch: 0,
		fontcasl: 0,
		fontVars: "'wght' 200, 'wdth' 60, 'ital' 0",
		font: 'GT Flexa VAR',
    listening: false,
		}
  },
  created(){
	window.addEventListener('SRResult', this.gotResult );
	window.addEventListener('SREnded', this.gotSRended );
  },
  methods:{
	startrec(){
		this.text = "";
    this.percsentiment =  0;
    this.percpitch =  0;
    this.centroid =  0;
    this.percvolume = 0;
		let event = new Event('startSR');
		console.log('dispatch event startSR')
		window.dispatchEvent(event);
    this.listening = true;
	},
  gotSRended(){
    if(	this.text == ""){
      this.text = "Drücke Start";
      this.fontVars= "'wght' 200, 'wdth' 60, 'ital' 0";
      this.font= 'GT Flexa VAR';
      this.percsentiment =  0;
      this.percpitch =  0;
      this.centroid =  0;
      this.percvolume = 0;
    }
    this.$parent.$refs.Selectors.doneRec();
    this.listening = false;
  },
  gotResult(event){
    this.$parent.$refs.Selectors.doneRec();
		console.log("got result called")
		console.log(event.detail);
		this.text = event.detail.text
		let calssifytext = event.detail.text.replace('ß', 'ss')
		this.sentimentclassification = ml.classify(calssifytext);
		console.log("SENTIMENT");
		console.log(this.sentimentclassification);
        // map sentiment 
		this.sentiment = Math.floor(map(this.sentimentclassification,-0.1,0.1,0,100,true))
		this.percsentiment  = Math.floor(map(this.sentimentclassification,-0.1,0.1,0,100,true))

		this.volume = event.detail.volume
		this.percvolume = event.detail.percvolume
		
		this.percpitch = event.detail.percpitch

		this.fontpitch = event.detail.fontpitch

		console.log(this.$parent.font);

		this.font = this.$parent.font;

		let fontvariation;

		if(this.font == 'GT Flexa VAR' ){
			let fvolume = map(this.percvolume,0,100,100,800,true)
			let fptich = map(this.percpitch,0,100,0,200,true)
			let fsentiment = map(this.percsentiment,0,100,0,100,true)
			// font variation Flexa 
			// font-variation-settings: "'wght' 317, 'wdth' 0, 'ital' 0"
			fontvariation = "'wght' " + fvolume + ", 'wdth' " + fptich  + ", 'ital' " + fsentiment + ";"
		}else if (this.font == 'cheee'){
			let fvolume = map(this.percvolume,0,100,0,1000,true)
			let fptich = map(this.percpitch,0,100,0,1000,true)
			let fsentiment = map(this.percsentiment,0,100,0,1000,true)
			fontvariation = "'yest' " + fvolume + ", 'grvt' " + fptich  + ", 'temp' " + fsentiment + ";"
		}else{
			let fvolume = map(this.percvolume,0,100,0,100,true)
			let fptich = this.percpitch;
			let fsentiment = this.percsentiment 
			// font variation phase
			// 	font-variation-settings: "phon" 67, "phtw" 9, "phth" 45;
			fontvariation = "'phon' " + fvolume + ", 'phtw' " + fptich  + ", 'phth' " + fsentiment + ";"
		}

		
        // font variation string bauen
        console.log(fontvariation)
		
        
        this.fontVars = fontvariation;
		console.log(this.fontVars);
		// font var recursiv
		// this.fontVars.fontVariationSettings = "'slnt' "+event.detail.fontpitch + ", 'CASL' " + this.fontcasl ; // 'slnt' 12, 'CASL' 0.45
		this.centroid = event.detail.centroid;
    this.listening =  false;

	},
  setFont(){
    this.font = this.$parent.font;

    let fontvariation;

    if(this.font == 'GT Flexa VAR' ){
      let fvolume = map(this.percvolume,0,100,100,800,true)
      let fptich = map(this.percpitch,0,100,0,200,true)
      let fsentiment = map(this.percsentiment,0,100,0,100,true)
      // font variation Flexa
      // font-variation-settings: "'wght' 317, 'wdth' 0, 'ital' 0"
      fontvariation = "'wght' " + fvolume + ", 'wdth' " + fptich  + ", 'ital' " + fsentiment + ";"
    }else if (this.font == 'cheee'){
      let fvolume = map(this.percvolume,0,100,0,1000,true)
      let fptich = map(this.percpitch,0,100,0,1000,true)
      let fsentiment = map(this.percsentiment,0,100,0,1000,true)
      fontvariation = "'yest' " + fvolume + ", 'grvt' " + fptich  + ", 'temp' " + fsentiment + ";"
    }else{
      let fvolume = map(this.percvolume,0,100,0,100,true)
      let fptich = this.percpitch;
      let fsentiment = this.percsentiment
      // font variation phase
      // 	font-variation-settings: "phon" 67, "phtw" 9, "phth" 45;
      fontvariation = "'phon' " + fvolume + ", 'phtw' " + fptich  + ", 'phth' " + fsentiment + ";"
    }


    // font variation string bauen
    console.log(fontvariation)


    this.fontVars = fontvariation;
    console.log(this.fontVars);
  }
}
}

</script>

<style >
#results {
	align-self: center;
}

#text {
	font-size: 70px;
	text-align: center;
	color: var(--fontcolor);;	
	text-transform: none;
}

#text button{
	text-transform:uppercase;
	font-family: 'soehne-fett';
	border: 2px solid var(--fontcolor);;
	background: transparent;
	color: var(--fontcolor);;
	font-size: 22px;
	padding: 5px 20px;
	cursor: pointer;
}

#text button:hover{
	border: 1px solid var(--fontcolor);;
	background: var(--fontcolor);;
	color: black;
}

#text p{
	color: var(--fontcolor);
}

#debug {
	position: absolute;
	right: 10px;
	top: 10px;
	display: flex;
}

#debug h4 {
	font-family: 'soehne-mono';
	font-weight: normal;
	margin: 0 10px 0 0;
	padding:0;
	font-size: 18px;
}

#debug div.results{
	color: var(--fontcolor);;
	font-size: 18px;
	font-family: 'soehne-mono';
	display: grid;
	grid-row-gap: 5px;
}
#debug div.results .item {
	display: grid;
	grid-column-gap: 20px;
	align-items: center;
	grid-template-columns: 150px 1fr 100px;
}
#debug div.results .item span{
	display: flex;
	justify-content: flex-end;
}
#debug div.results .item span:after {
	content: '=';
	margin-left: 20px;
}

#debug div.results .item .result-slider {
	width: 100%;
	height: 4px;
	border: 2px solid var(--fontcolor);;
}


#debug div.results .item .result-slider div{
	background: var(--fontcolor);;
	height: 4px;
	width: auto;
}

</style>