<template>
<div id="about">
<div class="content">
	<p class="lead align-center">Typemotion is an experimental 
Voice- User-Interface that uses variable typography to typographically implement and visualize human speech input with the focus on tonality.</p> 
</div>	
</div>
</template>

<script>
export default {
  name: 'About'
}
</script>